import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import devopslifecycle from "../../assets/icons/devopsLifeCycle.svg"
import migrateToCloud from "../../assets/icons/migrateToCloud.svg"
import applicationModernization from "../../assets/icons/applicationModernization1.svg"
import { graphql } from "gatsby"

const seoTitle = "Google Cloud Platform"
const seoDescription =
  "Atyeti's modernization capability helps you determine the right path to take to improve your current applications or to replace them with entirely new ones."
const keyword =
  " Application modernization, modernization, Kubernetes, Microservices, Refactoring, DevOps, Agile, Cloud Migration Automated Testing, Replatforming"
const mainHeading = "Google Cloud Platform"
// const mainPara = `As a Google Cloud partner Engineering Services designed for Google Cloud.`
const mainPara = `With decades of experience with helping modernize IT, Atyeti helps clients
achieve their digital transformation goals with our Google expertise.
As a Google Cloud Partner, we can bring together world class talent in all
aspects of GCP. We enable companies to modernize their infrastructure,
secure their workloads, and take data-driven decisions leveraging the power
of Google Cloud.`
const serviceIcons = [
  { id: 1, name: "Cloud Security", icon: checkMark },
  {
    id: 2,
    name: "Infrastructure Modernization and Automation",
    icon: checkMark,
  },
  { id: 3, name: "Cloud Migration", icon: checkMark },
  { id: 4, name: "Data Engineering and Analytics", icon: checkMark },
  { id: 5, name: "Data Science", icon: checkMark },
  { id: 6, name: "Cloud Cost  Optimization", icon: checkMark },
  { id: 7, name: "Automated Testing", icon: checkMark },
  { id: 8, name: "Replatforming", icon: checkMark },
]

const section2Heading = `Our Services`
const section2Para = `Our services in GCP include Infrastructure Services, Data Warehousing and Analytics, Data
Center Modernization and Cloud Migration Services.<br/> Learn more about our <a href="/cloud-solutions"> cloud services.</a>`

const cardItems = [
  {
    id: 1,
    icon: applicationModernization,
    heading: `Infrastructure`,
    listItems: (
      <>
        <li> Cloud Migration</li>
        <li>Anthos: Hybrid/Multi-cloud App platform,</li>
        <li> Custom-built app migration</li>
        <li> Improve software delivery pipeline</li>
        <li>Security</li>
        <li>Cloud Cost Governance</li>
      </>
    ),
  },
  {
    id: 2,
    icon: migrateToCloud,
    heading: `Data`,
    listItems: (
      <>
        <li>Looker</li>
        <li>Data Lake Modernization</li>
        <li> Data Warehouse Modernization</li>
        <li>Data Analytics and Visualization</li>
        <li>Migrate Oracle Workloads to Google Cloud</li>
        <li>MySQL/Postgres SQL migration</li>
      </>
    ),
  },
  {
    id: 3,
    icon: devopslifecycle,
    heading: `AI,ML`,
    listItems: (
      <>
        <li>Defining the AI Roadmap: estimate the ROI</li>
        <li>
          Model Development: Our AI/ML will help maintain a high level of
          expertise for faster turnaround.
        </li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="google-cloud-service"
  />
)
export const query = graphql`
  query GoogleCloudQuery {
    casestudyJson(mainheading: { eq: "google-cloud-service" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
